<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewDepartmentForm"
        ref="addNewDepartmentForm"
        v-model="isFormValid"
        class="custom-form pt-6"
        @submit.prevent="submitNewDepartmentForm"
      >
        <v-row>
          <v-col cols="9">
            <v-text-field
              outlined
              label="Oddział"
              v-model="department"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę oddziału"
            />
          </v-col>

          <v-col cols="3">
            <v-text-field
              outlined
              label="Symbol oddziału"
              v-model="invoiceCode"
              placeholder="Wpisz symbol"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Telefon do centrali"
              v-model="phoneNumber"
              :rules="[rules.required, rules.phoneNumber]"
              validate-on-blur
              v-mask="getFieldMask('phoneNumber')"
              placeholder="Wpisz numer telefonu"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              outlined
              label="Numer konta bankowego"
              v-model="bankAccount"
              :rules="[rules.required, rules.bankAccount]"
              validate-on-blur
              v-mask="getFieldMask('bankAccount')"
              placeholder="Wpisz numer konta bankowego"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <gmap-autocomplete
              class="introInput"
              @place_changed="getAddressData"
              :options="autocompleteOptions"
              select-first-on-enter
            >
              <template #input="slotProps">
                <v-text-field
                  outlined
                  prepend-inner-icon="place"
                  placeholder="Wyszukaj adres"
                  label="Adres bazy oddziału"
                  :value="address.address"
                  ref="input"
                  validate-on-blur
                  hide-details
                  @listeners="slotProps.listeners"
                  @attrs="slotProps.attrs"
                >
                  <template #prepend-inner>
                    <Icon
                      name="search"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>
            </gmap-autocomplete>
          </v-col>

          <v-col
            cols="12"
            class="d-flex align-start pt-0 mb-4"
          >
            <v-input
              :value="address.address"
              :rules="[rules.required]"
              hide-details
              class="validated-label"
            >
              <div class="mr-1">
                Wybrany adres:
              </div>
              <span class="font-weight-medium">{{ address.address || '-' }}</span>
            </v-input>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewDepartmentForm"
      >
        Dodaj oddział
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import { getFieldMask, getGmapsAddressParts } from '../../utils'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      department: null,
      phoneNumber: null,
      bankAccount: null,
      invoiceCode: null,
      address: {
        address: null,
        lat: null,
        lng: null
      },
      isFormValid: true,
      autocompleteOptions: {
        fields: ['adr_address', 'formatted_address', 'geometry.location'],
        componentRestrictions: {
          country: ['pl']
        }
      },
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.departments.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewDepartment: 'departments/addNewItem',
      getDepartments: 'departments/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    getFieldMask,
    getAddressData (e) {
      const { formattedAddress, lat, lng } = getGmapsAddressParts(e)
      this.address = {
        address: formattedAddress,
        lat,
        lng,
      }
    },
    submitNewDepartmentForm () {
      this.$refs.addNewDepartmentForm.validate()
      if (this.isFormValid) {
        const params = {
          name: this.department,
          address: this.address,
          phoneNumber: this.phoneNumber
        }
        this.addNewDepartment({ params })
          .then(() => {
            this.getDepartments()
            this.$refs.addNewDepartmentForm.reset()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
